import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { getSrc } from 'gatsby-plugin-image';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import FaqList from '../components/Faq';

import { breakpoint } from '../theme';
import { SiteInformation, PageContent } from '../utils/fragments';

const Container = styled.div`
  display: flex;
  max-width: ${(props) => props.theme.grid.maxWidthText};
  /* max-width: ${rem(960)}; */
  margin: ${rem(0)} auto 0;
  padding-top: ${rem(50)};
  padding-bottom: ${rem(100)};
`;

const Main = styled.main`
  flex-grow: 1;
`;

const Sidebar = styled.aside`
  width: ${rem(320)};
  flex-shrink: 1;
`;

const PostContent = styled.div`
  font-size: ${rem(16.5)};

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }

  & .wp-block-image {
    margin-top: ${rem(32)};
    margin-bottom: ${rem(22)};
  }

  & figcaption {
    color: ${(props) => props.theme.palette.colors.fadedText};
    font-size: ${rem(14)};
    font-weight: ${(props) => props.theme.typography.weights.primary.normal};
    font-family: ${(props) => props.theme.typography.fonts.primary};
  }
`;

const FaqSection = styled(Row)`
  font-size: ${rem(17)};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(18)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(20)};
  }
`;

function Faq({ data }) {
  const { page, faqs } = data;

  useLangRedirect(page);

  if (!page) {
    return null;
  }

  const { featuredImage, elementorDataRendered, elementorEditMode, lang, seo } =
    page;

  const isCustomLayout = elementorEditMode === 'builder';
  const featuredMedia = featuredImage?.node;
  const itemImageSrc = getSrc(featuredMedia?.localFile);

  const withHero = true;

  const faqItems = faqs.edges.map(({ node: item }) => {
    return {
      // '@type': 'Question',
      id: item.id,
      name: item.title,
      // acceptedAnswer: {
      //   '@type': 'Answer',
      text: item.content,
      // },
    };
  });

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        image={itemImageSrc}
        faq={faqItems}
        page={page}
        lang={lang}
      />

      {withHero && (
        <Hero
          heading={page.title}
          // image={page.featured_media?.localFile}
          pageTitle
          useImageFallback={false}
          // actions="Actions"
        />
      )}

      {false && (
        <>
          {isCustomLayout ? (
            <div
              dangerouslySetInnerHTML={{ __html: elementorDataRendered }} // eslint-disable-line react/no-danger
            />
          ) : (
            <Container>
              <Main>
                {page.content && (
                  <Row>
                    <Col>
                      <PostContent
                        dangerouslySetInnerHTML={{ __html: page.content }} // eslint-disable-line react/no-danger
                      />
                    </Col>
                  </Row>
                )}
              </Main>
            </Container>
          )}
        </>
      )}

      <Container>
        {false && (
          <Sidebar>
            Här kan vi visa faq kategorier som tex. Payment, Training etc.
          </Sidebar>
        )}
        <Main>
          {faqItems && (
            <FaqSection>
              <Col>
                <FaqList items={faqItems} />
              </Col>
            </FaqSection>
          )}
        </Main>
      </Container>
    </Layout>
  );
}

Faq.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

Faq.fragments = [PageContent, SiteInformation];

export const query = graphql`
  query ($id: String!, $lang: String!) {
    page: wpPage(id: { eq: $id }) {
      elementorDataRendered
      elementorEditMode
      ...PageContent
    }
    faqs: allWpFaq(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          id
          title
          content
          lang
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`;

export default Faq;
