import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { em, rem } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { colors, breakpoint } from '../../theme';

const Item = styled.div`
  position: relative;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Title = styled.h5`
  margin: 0;
  font-size: ${em(15)};
  font-weight: ${(props) => props.theme.typography.weights.heading.bold};
  line-height: 1.14;
  flex-grow: 1;

  ${breakpoint('medium')} {
    font-size: ${em(16)};
  }

  /*  ${breakpoint('mediumlarge')} {
    font-size: ${em(34)};
  } */

  ${breakpoint('large')} {
    font-size: ${em(17)};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${rem(18)};
  vertical-align: middle;
  margin-left: 0.5em;
  margin-top: -0.1em;
  flex-shrink: 1;
`;

const Toggle = styled.div`
  display: flex;
  cursor: pointer;
  padding: 1.25em ${(props) => props.theme.spacing.gutter};
  background-color: ${(props) => props.theme.palette.background.content};
  border-top: 1px solid ${(props) => props.theme.global.border.color};

  &.active {
    ${Icon} {
      transform: rotate(180deg);
    }
  }
`;

const Content = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.palette.background.content};
  font-size: ${rem(16)};
  overflow-y: auto;
  transition: max-height 300ms ease;
  max-height: 0px;
`;

const Inner = styled.div`
  padding: 0 ${(props) => props.theme.spacing.gutter} 1rem;
`;

function AccordionItem(props) {
  const itemRef = useRef(null);
  const [setHeight, setHeightState] = useState('0px');
  const [isActive, setActive] = useState(false);
  const { item } = props;
  const { text } = item;

  function handleToggle() {
    setActive(!isActive);
    setHeightState(isActive ? '0px' : `${itemRef.current.scrollHeight}px`);
  }

  return (
    <Item itemProp="name" className={isActive && 'active'}>
      <Toggle
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
        onClick={handleToggle}
        className={isActive && 'active'}
      >
        <Title>{item.name}</Title>
        <Icon icon={faChevronDown} color={colors.black} />
      </Toggle>
      <Content
        className={isActive && 'active'}
        ref={itemRef}
        style={{
          maxHeight: `${setHeight}`,
        }}
      >
        <Inner
          itemprop="text"
          dangerouslySetInnerHTML={{ __html: text }} // eslint-disable-line react/no-danger
        />
      </Content>
    </Item>
  );
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
  }).isRequired,
};

export default AccordionItem;
