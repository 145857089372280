import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AccordionItem from './FaqItem';

const Root = styled.div`
  background-color: ${(props) => props.theme.palette.background.content};
`;

function Accordion({ items, color }) {
  return (
    <Root
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      {items.map((item) => {
        return <AccordionItem key={item.id} item={item} color={color} />;
      })}
    </Root>
  );
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      text: PropTypes.node.isRequired,
    }),
  ).isRequired,
  color: PropTypes.oneOf(['default', 'negative']),
};

Accordion.defaultProps = {
  color: 'default',
};

export default Accordion;
